<template>
  <div class="vip">
    <div class="navbar-box">
      <van-nav-bar left-arrow @click-left="onClickLeft">
        <template #left>
          <van-icon name="arrow-left" size="20" color="#000000" />
        </template>
        <template #title>
          <div class="navbar-title">VIP Application</div>
        </template>
      </van-nav-bar>
    </div>

    <div class="content div-bg">
      <van-image width="100%" :src="require('./img/img1.png')" />
      <div class="bg"></div>
    </div>

    <div class="btn div-bg" @click="to_vip_account">
      <van-image width="100%" :src="require('./img/btn.png')" />
      <div class="bg"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  methods: {
    onClickLeft() {
      this.$router.back();
    },
    to_vip_account() {
      this.$router.push({ path: "/vip/account" });
    },
  },
};
</script>

<style lang="scss" scoped>
.vip {
  background-color: #f1f1f1;
  min-height: 100vh;

  .navbar-box {
    position: sticky;
    top: 0;
    border-radius: 0 0 0.25rem 0.25rem;
    overflow: hidden;
    z-index: 999;
    border-bottom: 1px solid #ffffff;

    .van-nav-bar__title {
      overflow: initial;
    }

    .van-nav-bar__content {
      height: 5.5rem;
    }

    .navbar-title {
      display: flex;
      align-items: center;
      font-size: 2.25rem;
      color: #000000;
      font-weight: bold;
      position: relative;
    }
  }

  .content {
    padding: 0;
  }

  .btn {
    margin-top: 1.25rem;
    position: sticky;
    bottom: 0;
    z-index: 999;
    line-height: 0;
  }
}
</style>